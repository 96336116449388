import React from 'react';
import { fluidImage } from '../util/fluidImage';
import { pagePreviewImage } from '../util/pagePreviewImage';

import { Link, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Img from 'gatsby-image';

import Layout from '../components/Layout';

const IndexPage = ({ data }) => (
    <Layout site="bahaide" title="Bahá’í in Deutschland">
        <main id="main" role="main">
            <div className="intro-block intro-block--no-border">
                <Img
                    backgroundColor="#f8f6f4"
                    fluid={data.heroImage.childImageSharp.fluid}
                />
                <div className="text-holder text-holder--center">
                    <blockquote>
                        <q>
                            „Die Erde ist nur ein Land, und alle Menschen sind
                            seine Bürger.“
                        </q>
                    </blockquote>
                </div>
            </div>

    <div
                className="container"
                style={{
                    marginTop: '30px',
                }}
            >
                <OutboundLink href="https://www.youtube.com/watch?v=7BUKJK8wcwg">
                    <div className="banner-holder">
                        <div className="img-holder">
                            <Img
                                backgroundColor="#A7BDE6"
                    
                            />
                        </div>
                        <div className="text-holder">
                            <div className="frame">
                                <h1>Film: Ein weitreichender Ausblick</h1>
                                <p>
                                    Der 72-minütige Film "Ein weitreichender Ausblick" beleuchtet die Bemühungen der Bahá'í-Gemeinde in vier Regionen der Welt, in denen Menschen, Gemeinden und Institutionen gemeinsam danach streben, die verwandelnde Kraft der Bahá'í-Lehren für den sozialen Wandel zu nutzen.
                                </p>{' '}
                                <div className="btn-holder">
                                    <OutboundLink
                                        href="https://www.youtube.com/watch?v=7BUKJK8wcwg"
                                        className="btn btn-blue"
                                    >
                                        Zu dem Film
                                    </OutboundLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </OutboundLink>
            </div>
        

          

            <section className="pages-block">
                <div className="container">
                    <div className="side-col">
                        <Link to="/woran-bahai-glauben/">
                            <h1>Woran Bahá'í glauben</h1>
                        </Link>
                        <p>
                            Einblicke in einige Glaubensgrundsätze, die Bahá'í
                            weltweit inspirieren und ihnen eine Vision für die
                            Einheit der Menschheit aufzeigt.
                        </p>{' '}
                        <div className="pages-list">
                            <div className="page-preview small">
                                {/* <a href="#">
                  <div className="text-holder">
                  </div>
                </a> */}
                            </div>
                        </div>
                        <blockquote>
                            <q>
                                Der ist wirklich ein Mensch, der sich heute dem
                                Dienst am ganzen Menschengeschlecht hingibt.
                            </q>
                            <cite>BAHÁ’U’LLÁH</cite>
                        </blockquote>
                    </div>
                    <div className="pages-list">
                        <div className="page-preview">
                            <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.bahaullahUndSeinAnspruchFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Bahá’u’lláh und Sein Anspruch</h2>
                                    <p>
                                        Bahá’u’lláh und Sein Anspruch als
                                        Überbringer einer göttlichen Botschaft
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/woran-bahai-glauben/gott-und-der-mensch/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.gottUndDerMenschFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Gott und der Mensch</h2>
                                    <p>
                                        Einheit, Fortschreitende Offenbarung und
                                        Kultur als Prozess
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data
                                                .derEinzelneUndDieGesellschaftFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Der Einzelne und die Gesellschaft</h2>
                                    <p>
                                        Eine wechselseitige Beziehung auf dem
                                        Weg zur Weltkultur
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/woran-bahai-glauben/die-natur-des-menschen/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.dieNaturDesMenschenFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Die Natur des Menschen</h2>
                                    <p>
                                        Die Natur des Menschen ist eine mit
                                        Vernunft begabte Seele
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="add-pages-block">
                <div className="container">
                    <div className="top-holder">
                        <Link to="/gemeinsames-handeln/">
                            <h1>Gemeinsames Handeln</h1>
                        </Link>
                        Lebhafte Gemeinden kultivieren{' '}
                    </div>
                    <div className="pages-list">
                        <div className="page-preview">
                            <Link to="/gemeinsames-handeln/beteiligung-an-diskursen/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.beteiligungAnDiskursenFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Beteiligung an Diskursen</h2>
                                    <p>
                                        Bemühung zum gesellschaftlichen
                                        Fortschritt beizutragen
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/gemeinsames-handeln/die-bahai-in-deutschland/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data
                                                .dieBahaiGemeindeInDeutschlandFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Die Bahá’í in Deutschland</h2>
                                    <p>
                                        Beginn und Entwicklung der
                                        Bahá’í-Gemeinde in Deutschland
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/gemeinsames-handeln/jugend/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.jugendFeature.childImageSharp
                                                .fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Jugend</h2>
                                    <p>
                                        Junge Menschen zeigen Initiative und
                                        Verantwortung für ihr Umfeld
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/gemeinsames-handeln/familie-und-kinder/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.familieUndKinderFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Familie und Kinder</h2>
                                    <p>
                                        Die Familie als kleinste Einheit einer
                                        Gesellschaft{' '}
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/gemeinsames-handeln/andacht-und-dienst/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.andachtUndDienstFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Andacht und Dienst</h2>
                                    <p>
                                        Der Ausdruck des Wortes Gottes im
                                        Handeln
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="page-preview">
                            <Link to="/gemeinsames-handeln/gemeinsames-lernen/">
                                <div className="img-holder">
                                    <Img
                                        backgroundColor="#f8f6f4"
                                        fluid={
                                            data.gemeinsamesLernenFeature
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="text-holder">
                                    <h2>Gemeinsames Lernen</h2>
                                    <p>
                                        Teilnehmen und aktives Mitgestalten in
                                        eigener Umgebung
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <p className="separator" />
                </div>
            </section>

            <div className="item-block">
                <div className="container">
                    <div className="map-holder">
                        <Img
                            fluid={
                                data.mapBackgroundImage.childImageSharp.fluid
                            }
                            alt=""
                        />
                    </div>
                    <div className="text-frame">
                        <strong className="title">
                            Möchten Sie mit der Bahá’í-Gemeinde vor Ort Kontakt
                            aufnehmen oder mehr erfahren?
                        </strong>
                        <p>
                            Gemeinsam mit Freunden und Menschen aus ihrer
                            Umgebung sind Bahá’i bestrebt, Umfelder zu schaffen,
                            die das geistige und gesellschaftliche Wohlergehen
                            fördern.
                        </p>{' '}
                        <div className="pages-list">
                            <div className="page-preview small">
                                <Link to="/bahai-kennenlernen/">
                                    <div className="img-holder">
                                        <Img
                                            backgroundColor="#f8f6f4"
                                            fluid={
                                                data.bahaiKennenlernenFeature
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                    <div className="text-holder">
                                        <h2>Bahá'í kennenlernen</h2>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<div
                className="item-block"
                style={{
              
                    paddingLeft: '30px',
                    paddingRight: '30px',
                }}
            >
                <OutboundLink href="https://bibliothek.bahai.de/">
                    <div className="banner-holder">
                        <div className="img-holder">
                            <Img
                                backgroundColor="#427ab2"
                                fluid={data.wave.childImageSharp.fluid}
                            />
                        </div>
                        <div className="text-holder">
                            <div className="frame">
                                <h1>Bahá'í-Online-Bibliothek</h1>
                                <p>
                                    Die Bahá'í-Online-Bibliothek ist die offizielle Online-Quelle für Bahá'í-Schriften in deutscher Übersetzung. Sie enthält ausgewählte Werke von Bahá'u'lláh, dem Báb, 'Abdu'l-Bahá, Shoghi Effendi und dem Universalen Haus der Gerechtigkeit sowie andere Bahá'í-Texte.
                                </p>{' '}
                                <div className="btn-holder">
                                    <OutboundLink
                                        href="https://bibliothek.bahai.de/"
                                        className="btn btn-blue"
                                    >
                                        Zur Bibliothek
                                    </OutboundLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </OutboundLink>
            </div>

            <div className="banner">
                <div className="container">
                    <Link to="/haus-der-andacht/">
                        <div className="banner-holder">
                            <div className="img-holder">
                                <Img
                                    alt=""
                                    fluid={
                                        data.hausDerAndachtFeature
                                            .childImageSharp.fluid
                                    }
                                />
                            </div>
                            <div className="text-holder">
                                <div className="frame">
                                    <h1>Das Haus der Andacht von Europa</h1>
                                    <p>
                                        Das bisher einzige „Haus der Andacht“
                                        der Bahá’í in Europa ist ein Ort der
                                        Besinnung und dem Wort Gottes aus allen
                                        Religionen gewidmet. Es bietet Menschen
                                        gleich welcher Herkunft die Gelegenheit,
                                        sich Gott zuzuwenden – sie alle sind
                                        herzlich willkommen.
                                    </p>{' '}
                                    <div className="btn-holder">
                                        <Link
                                            to="/haus-der-andacht/"
                                            className="btn btn-blue desktop-hidden"
                                        >
                                            Zur Webseite
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </main>
    </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
    query {
        heroImage: file(relativePath: { eq: "homepage-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2000, maxHeight: 900, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
        }
        bahaullahUndSeinAnspruchFeature: file(
            relativePath: { eq: "bahaullah-und-sein-anspruch-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        gottUndDerMenschFeature: file(
            relativePath: { eq: "gott-und-der-mensch-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        derEinzelneUndDieGesellschaftFeature: file(
            relativePath: {
                eq: "der-einzelne-und-die-gesellschaft-feature.jpg"
            }
        ) {
            ...pagePreviewImage
        }
        dieNaturDesMenschenFeature: file(
            relativePath: {
                eq: "Kachel_Die-Natur-des-Menschen-a-e1487613372304.jpg"
            }
        ) {
            ...pagePreviewImage
        }
        beteiligungAnDiskursenFeature: file(
            relativePath: {
                eq: "beteiligung-an-gesellschaftlichen-diskursen-feature-1.jpg"
            }
        ) {
            ...pagePreviewImage
        }
        dieBahaiGemeindeInDeutschlandFeature: file(
            relativePath: { eq: "die-bahai-in-deutschland-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        jugendFeature: file(relativePath: { eq: "jugend-feature.jpg" }) {
            ...pagePreviewImage
        }
        familieUndKinderFeature: file(
            relativePath: { eq: "familie-und-kinder-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        andachtUndDienstFeature: file(
            relativePath: { eq: "andacht-und-dienst-img-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        gemeinsamesLernenFeature: file(
            relativePath: { eq: "gemeinsames-lernen-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        mapBackgroundImage: file(relativePath: { eq: "map.png" }) {
            ...fluidImage
        }
        bahaiKennenlernenFeature: file(
            relativePath: {
                eq: "bahai-kennenlernen-feature-e1499439811331.jpg"
            }
        ) {
            ...pagePreviewImage
        }
        hausDerAndachtFeature: file(relativePath: { eq: "HDA-28-2.jpg" }) {
            ...fluidImage
        }
        wave: file(
            relativePath: { eq: "daniel-olah-Y-QjZS9FRU0-unsplash.jpg" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 1280
                    maxHeight: 500
                    quality: 70
                    cropFocus: SOUTHEAST
                ) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
        }
    }
`;
